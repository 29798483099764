function ModalWindow() {
	this.modal     = null;
	this.id        = "__js-modal-window-ll";
	this.className = "modal-win";
	this.openClass = "open";
	this.closeId   = "__js-modal-window-ll-close";
}

ModalWindow.prototype.create = function() {
	var modal = document.getElementById(this.id);

	if(!modal) {
		modal = document.createElement("div");
		modal.id = this.id;
		modal.classList.add(this.className);

		document.body.appendChild(modal);
	}

	this.modal = modal instanceof HTMLElement ? modal : null;
};

ModalWindow.prototype.open = function(frame = "") {
	var closeId  = this.closeId;
	var openCls  = this.openClass;
	var modal    = this.modal;
	var template = `
		<div class="${this.className}__wrapper">
			<div id="${this.closeId}" class="${this.className}__close"></div>

			<div class="${this.className}__frame">${frame}</div>
		</div>
	`;

	if(this.modal instanceof HTMLElement) {
		this.modal.innerHTML = template;
		this.modal.classList.add(this.openClass);

		stopScroll(true);
	}

	document.addEventListener("click", closeEvent);

	function closeEvent(event) {
		event = event || window.event;
		var target = event.target;

		if(target instanceof HTMLElement && target.id === closeId) {
			if(modal instanceof HTMLElement) {
				modal.classList.remove(openCls);
				modal.innerHTML = "";

				stopScroll(false);

				setTimeout(() => {
					document.removeEventListener("click", closeEvent);
				}, 100);
			}
		}
	}

	function stopScroll(flag) {
		var html = document.getElementsByTagName("html")[0];
		var body = document.body;

		if(flag === true) {
			html.style.overflow = "hidden";
			body.style.overflow = "hidden";
		} else {
			html.style.overflow = "";
			body.style.overflow = "";
		}
	}
};