(function($) {

	"use strict";

    /*------------------------------------------
        = FUNCTIONS
    -------------------------------------------*/
    // Check ie and version
    function isIE () {
        var myNav = navigator.userAgent.toLowerCase();
        return (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1], 10) : false;
    }


    // Toggle mobile navigation
    function toggleMobileNavigation() {
        var navbar = $(".navigation-holder");
        var openBtn = $(".navbar-header .open-btn");
        var closeBtn = $(".navigation-holder .close-navbar");

        openBtn.on("click", function() {
            if (!navbar.hasClass("slideInn")) {
                navbar.addClass("slideInn");
            }
            return false;
        })

        closeBtn.on("click", function() {
            if (navbar.hasClass("slideInn")) {
                navbar.removeClass("slideInn");
            }
            return false;
        })
    }

    // toggleMobileNavigation(); ??????? WTF


    // Function for toggle a class for small menu
    function toggleClassForSmallNav() {
        var windowWidth = window.innerWidth;
        var mainNav = $("#navbar > ul");

        if (windowWidth <= 991) {
            mainNav.addClass("small-nav");
        } else {
            mainNav.removeClass("small-nav");
        }
    }

    toggleClassForSmallNav();


    // Function for small menu
    function smallNavFunctionality() {
        var windowWidth = window.innerWidth;
        var mainNav = $(".navigation-holder");
        var smallNav = $(".navigation-holder > .small-nav");
        var subMenu = smallNav.find(".sub-menu");
        var megamenu = smallNav.find(".mega-menu");
        var menuItemWidthSubMenu = smallNav.find(".menu-item-has-children > a");

        if (windowWidth <= 991) {
            subMenu.hide();
            megamenu.hide();
            menuItemWidthSubMenu.on("click", function(e) {
                var $this = $(this);
                $this.siblings().slideToggle();
                 e.preventDefault();
                e.stopImmediatePropagation();
            })
        } else if (windowWidth > 991) {
            mainNav.find(".sub-menu").show();
            mainNav.find(".mega-menu").show();
        }
    }

    smallNavFunctionality();


    // Parallax background
    function bgParallax() {
        if ($(".parallax").length) {
            $(".parallax").each(function() {
                var height = $(this).position().top;
                var resize     = height - $(window).scrollTop();
                var doParallax = -(resize/5);
                var positionValue   = doParallax + "px";
                var img = $(this).data("bg-image");

                $(this).css({
                    backgroundImage: "url(" + img + ")",
                    backgroundPosition: "50%",
                    backgroundSize: "cover",
                    backgroundAttachment: "fixed"
                });
            });
        }
    }


    // Hero slider background setting
    function sliderBgSetting() {
        if ($(".hero-slider .slide").length) {
            $(".hero-slider .slide").each(function() {
                var $this = $(this);
                var img = $this.find(".slider-bg").attr("src");

                $this.css({
                    backgroundImage: "url("+ img +")",
                    backgroundSize: "cover",
                    backgroundPosition: "center center"
                })
            });
        }
    }


    //Setting hero slider
    function heroSlider() {
        if ($(".hero-slider").length) {
            $(".hero-slider").slick({
                autoplay: true,
                autoplaySpeed: 6000,
                pauseOnHover: true,
                arrows: true,
                prevArrow: '<button type="button" class="slick-prev">Previous</button>',
                nextArrow: '<button type="button" class="slick-next">Next</button>',
                dots: true,
                fade: true,
                cssEase: 'linear'
            });
        }
    }

    //Take grid image and make it bg
    function makeBg($selector) {
        var selector = $selector;

        selector.each(function() {
            var $this = $(this),
                bgImage = $this.find(".bg-image"),
                bgImageSrc = bgImage.attr("src");

            bgImage.hide();

            $this.css({
                backgroundImage: "url(" + bgImageSrc + ")",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
            })
        })
    }


    /*------------------------------------------
        = HIDE PRELOADER
    -------------------------------------------*/
    function preloader() {
        if($('.preloader').length) {
            $('.preloader').delay(100).fadeOut(500, function() {

                //active wow
                wow.init();

                //Active heor slider
                heroSlider();

            });
        }
    }


    /*------------------------------------------
        = WOW ANIMATION SETTING
    -------------------------------------------*/
    var wow = new WOW({
        boxClass:     'wow',      // default
        animateClass: 'animated', // default
        offset:       0,          // default
        mobile:       true,       // default
        live:         true        // default
    });


    /*------------------------------------------
        = ACTIVE POPUP IMAGE
    -------------------------------------------*/
    if ($(".fancybox").length) {
        $(".fancybox").fancybox({
            openEffect  : "elastic",
            closeEffect : "elastic",
            wrapCSS     : "project-fancybox-title-style"
        });
    }


    /*------------------------------------------
        = POPUP VIDEO
    -------------------------------------------*/
    if ($(".video-btn").length) {
        $(".video-btn").on("click", function(){
            $.fancybox({
                href: this.href,
                type: $(this).data("type"),
                'title'         : this.title,
                helpers     : {
                    title : { type : 'inside' },
                    media : {}
                },

                beforeShow : function(){
                    $(".fancybox-wrap").addClass("gallery-fancybox");
                }
            });
            return false
        });
    }


    /*------------------------------------------
        = ACTIVE GALLERY POPUP IMAGE
    -------------------------------------------*/
    if ($(".popup-gallery").length) {
        $('.popup-gallery').magnificPopup({
            delegate: 'a',
            type: 'image',

            gallery: {
              enabled: true
            },

            zoom: {
                enabled: true,

                duration: 300,
                easing: 'ease-in-out',
                opener: function(openerElement) {
                    return openerElement.is('img') ? openerElement : openerElement.find('img');
                }
            }
        });
    }


    /*------------------------------------------
        = FUNCTION FORM SORTING GALLERY
    -------------------------------------------*/
    function sortingGallery() {
        if ($(".sortable-gallery .gallery-filters").length) {
            var $container = $('.gallery-container');
            $container.isotope({
                filter:'*',
                animationOptions: {
                    duration: 750,
                    easing: 'linear',
                    queue: false,
                }
            });

            $(".gallery-filters li a").on("click", function() {
                $('.gallery-filters li .current').removeClass('current');
                $(this).addClass('current');
                var selector = $(this).attr('data-filter');
                $container.isotope({
                    filter:selector,
                    animationOptions: {
                        duration: 750,
                        easing: 'linear',
                        queue: false,
                    }
                });
                return false;
            });
        }
    }

    sortingGallery();


    /*------------------------------------------
        = MASONRY GALLERY SETTING
    -------------------------------------------*/
    function masonryGridSetting() {
        if ($('.masonry-gallery').length) {
            var $grid =  $('.masonry-gallery').masonry({
                itemSelector: '.grid-item',
                columnWidth: '.grid-item',
                percentPosition: true
            });

            $grid.imagesLoaded().progress( function() {
                $grid.masonry('layout');
            });
        }
    }



    /*------------------------------------------
        = STICKY HEADER
    -------------------------------------------*/

    // Function for clone an element for sticky menu
    function cloneNavForSticyMenu($ele, $newElmClass) {
        $ele.addClass('original').clone().insertAfter($ele).addClass($newElmClass).removeClass('original');
    }

    // clone home style 1 navigation for sticky menu
    if ($('.site-header .navigation').length) {
        cloneNavForSticyMenu($('.site-header .navigation'), "sticky-header");
    }

    // Function for sticky menu
    function stickIt($stickyClass, $toggleClass) {

        if ($(window).scrollTop() >= 300) {
            var orgElement = $(".original");
            var coordsOrgElement = orgElement.offset();
            var leftOrgElement = coordsOrgElement.left;
            var widthOrgElement = orgElement.css("width");

            $stickyClass.addClass($toggleClass);

            $stickyClass.css({
                "width": widthOrgElement
            }).show();

            $(".original").css({
                "visibility": "hidden"
            });

        } else {

            $(".original").css({
                "visibility": "visible"
            });

            $stickyClass.removeClass($toggleClass);
        }
    }


    /*------------------------------------------
        = Header shopping cart toggle
    -------------------------------------------*/
    if($(".mini-cart").length) {
        var cartToggleBtn = $(".cart-toggle-btn");
        var cartContent = $(".top-cart-content");
        var body = $("body");

        cartToggleBtn.on("click", function(e) {
            cartContent.toggleClass("top-cart-content-toggle");
            e.stopPropagation();
        });

        body.on("click", function() {
            cartContent.removeClass("top-cart-content-toggle");
        }).find(cartContent).on("click", function(e) {
            e.stopPropagation();
        });
    }


    /*------------------------------------------
        = service slider
    -------------------------------------------*/
    if($(".service-slider".length)) {
        $(".service-slider").owlCarousel({
            autoplay:true,
            mouseDrag: false,
            smartSpeed: 300,
            margin: 30,
            loop:true,
            autoplayHoverPause:true,
            responsive: {
                0 : {
                    items: 1
                },

                600 : {
                    items: 2
                },

                992 : {
                    items: 3
                }
            }
        });
    }


    /*------------------------------------------
        = Testimonials slider
    -------------------------------------------*/
    if($(".testimonials-slider".length)) {
        $(".testimonials-slider").owlCarousel({
            //autoplay: true,
            mouseDrag: true,
            smartSpeed: 1000,
            loop:true,
            autoplayHoverPause:true,
            items: 1,
            dots: false,
            nav: true,
            navText: ['<i class="fa fa-angle-left"></i>','<i class="fa fa-angle-right"></i>']
        });
    }


    /*-------------------------------------------------------
        = Function for setting horizental overflow scroolbar
    --------------------------------------------------------*/
    function setMcustomScrollBar($selector) {
        var selector = $selector,
            windowWidth = window.innerWidth;

        if((selector.length) && (windowWidth >= 768)) {
            selector.addClass("mCustomScrollbar _mCS_1");
            selector.mCustomScrollbar({
                axis:"x",
                advanced:{
                    autoExpandHorizontalScroll: true
                },
                mouseWheel: "disable"
            });
        } else {
            selector.removeClass("mCustomScrollbar _mCS_1");
            selector.mCustomScrollbar("destroy");
        }
    }


    /*------------------------------------------
        = Partners slider
    -------------------------------------------*/
    if($(".partners-slider".length)) {
        $(".partners-slider").owlCarousel({
            autoplay:true,
            smartSpeed: 300,
            margin: 30,
            loop:false,
            autoplayHoverPause:true,
            dots: false,
            responsive: {
                0 : {
                    items: 2
                },

                600 : {
                    items: 3
                },

                992 : {
                    items: 4
                }
            }
        });
    }


    /*------------------------------------------
        = team slider
    -------------------------------------------*/
    if($(".team-slider".length)) {
        $(".team-slider").owlCarousel({
            // autoplay:true,
            smartSpeed: 300,
            margin: 30,
            loop:false,
            rewind:false,
            navRewind:false,
            autoplayHoverPause:true,
            nav: true,
            navText: ['<i class="fa fa-arrow-left">','<i class="fa fa-arrow-right">'],
            responsive: {
                0 : {
                    items: 1
                },

                550 : {
                    items: 2
                },

                895 : {
                    items: 3
                }
            }
        });
    }


    /*------------------------------------------
        = FAN FACT COUNT
    -------------------------------------------*/
    if ($(".start-count").length) {
        $('.counter').appear();
        $(document.body).on('appear', '.counter', function(e) {
            var $this = $(this),
            countTo = $this.attr('data-count');

            $({ countNum: $this.text()}).animate({
                countNum: countTo
            }, {
                duration: 3000,
                easing:'linear',
                step: function() {
                    $this.text(Math.floor(this.countNum));
                },
                complete: function() {
                    $this.text(this.countNum);
                }
            });
        });
    }


    /*------------------------------------------
        = Testimonials slider
    -------------------------------------------*/
    if($(".testimoials-s2-slider".length)) {
        $(".testimoials-s2-slider").owlCarousel({
            autoplay:true,
            smartSpeed: 300,
            margin: 30,
            loop:true,
            autoplayHoverPause:true,
            nav: true,
            navText: ['<i class="fa fa-arrow-left">','<i class="fa fa-arrow-right">'],
            dots: false,
            responsive: {
                0 : {
                    items: 1
                },

                768 : {
                    items: 2
                }
            }
        });
    }


    /*------------------------------------------
        = SHOP RANGE SLIDER
    -------------------------------------------*/
    if ($("#range").length) {
        $("#range").slider({
            min: 50,
            max: 1000,
            value: [85, 300],
            tooltip: "hide"
        });

        $("#range").on("slide", function(v1) {
            $("#min-value").text("$" + v1.value[0]);
            $("#max-value").text("$" + v1.value[1]);
        });
    }

    /*------------------------------------------
        = SHOP DETAILS PRODUCT SLIDER
    -------------------------------------------*/
    if ($(".shop-single-slider-wrapper").length) {
        $('.slider-for').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.slider-nav'
        });
        $('.slider-nav').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            asNavFor: '.slider-for',
            focusOnSelect: true,
            prevArrow: '<i class="nav-btn nav-btn-lt fa fa-long-arrow-left"></i>',
            nextArrow: '<i class="nav-btn nav-btn-rt fa fa-long-arrow-right"></i>',

            responsive: [
                {
                  breakpoint: 500,
                  settings: {
                    slidesToShow: 3,
                    infinite: true
                  }
                }
            ]

        });
    }


    // Bootstrap touchspin for product details page
    if ($("input[name='count-product']").length) {
        $("input[name='count-product']").TouchSpin({
            verticalbuttons: true
        });
    }

    //Recent proejct horizental scroolbar
    if($(".recent-projects-grids").length) {
        setMcustomScrollBar($(".recent-projects-grids"));
    }


    /*------------------------------------------
        = BACK TO TOP BTN SETTING
    -------------------------------------------*/
    $("body").append("<a href='#' class='back-to-top'><i class='fa fa-angle-up'></i></a>");

    function toggleBackToTopBtn() {
        var amountScrolled = 300;
        if ($(window).scrollTop() > amountScrolled) {
            $("a.back-to-top").fadeIn("slow");
        } else {
            $("a.back-to-top").fadeOut("slow");
        }
    }

    $(".back-to-top").on("click", function() {
        $("html,body").animate({
            scrollTop: 0
        }, 700);
        return false;
    });



    /*------------------------------------------
        = GOOGLE MAP
    -------------------------------------------*/
    function map() {

        // var locations = [
		// 	['25 South Grove Avenue Elgin, IL 60120 USA', 42.0367206, -88.2841505],
		// 	['49 Treegrove Circle Aurora, Ontario, Canada L4G 6M2', 44.0098779, -79.494882],
		// 	['P.C.: 300353 NO.1 Chuangxin Rd Xiaozhan Industrial Park, Jinnan Area, Tianjin, China', 38.9036556, 117.4187546],
		// ];


        var map = new google.maps.Map(document.getElementById('map'), {
            center: new google.maps.LatLng(42.0367206, 8.2841505),
            zoom: 2,
            scrollwheel: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        });

        var infowindow = new google.maps.InfoWindow({
            maxWidth: 160
        });


        $(window).resize(function () {
            if ($(document).width() < 650) {
                map.setZoom(1);
            } else {
                map.setZoom(2);
            }

            if ($(document).width() < 380) {
                map.setCenter(new google.maps.LatLng(42.0367206, -28.2841505));
            } else {
                map.setCenter(new google.maps.LatLng(42.0367206, 8.2841505));
            }

        });

        var marker, i;

        var locations = [{"address":"25 South Grove Avenue Elgin, IL 60120 USA","lat":"42.0367206","lng":"-88.2841505"},{"address":"49 Treegrove Circle Aurora, Ontario, Canada L4G 6M2","lat":"44.0098779","lng":"-79.494882"},{"address":"P.C.: 300353 NO.1 Chuangxin Rd Xiaozhan Industrial Park, Jinnan Area, Tianjin, China","lat":"38.9036556","lng":"117.4187546"}];

		$.post( "/wp-admin/admin-ajax.php", {action: "getLocations"})
			.done(function (data) {
			    var locations = JSON.parse(data);

				for (i = 0; i < locations.length; i++) {
					marker = new google.maps.Marker({
						position: new google.maps.LatLng(locations[i].lat, locations[i].lng),
						map: map,
                        icon:'/wp-content/themes/TEEE/front/Industrial/full-width/industrial/assets/images/map-marker.png'

                    });

					google.maps.event.addListener(marker, 'click', (function(marker, i) {
						return function() {
							infowindow.setContent(locations[i].address);
							infowindow.open(map, marker);

						}
					})(marker, i));
				}
			}).error((e) => {
			console.log(e.status);
		});


        map.set('styles',

            [
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#e9e9e9"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 29
                        },
                        {
                            "weight": 0.2
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 18
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        },
                        {
                            "lightness": 21
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#dedede"
                        },
                        {
                            "lightness": 21
                        }
                    ]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "saturation": 36
                        },
                        {
                            "color": "#333333"
                        },
                        {
                            "lightness": 40
                        }
                    ]
                },
                {
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f2f2f2"
                        },
                        {
                            "lightness": 19
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#fefefe"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#fefefe"
                        },
                        {
                            "lightness": 17
                        },
                        {
                            "weight": 1.2
                        }
                    ]
                }
            ]
        );

    };


    /*------------------------------------------
        = CONTACT FORM SUBMISSION
    -------------------------------------------*/
    if ($("#contact-form").length) {

        let wpcf7Elm = document.querySelector( '.wpcf7' );

        $("#contact-form").validate({
            rules: {
                name: {
                    minlength: 2
                },


            },

            // messages: {
            //     name: "Please enter your name",
            //     email: "Please enter your email",
            //     phone: "Please enter your phone",
            //     select: "Select an item"
            // },

            // submitHandler: function (form) {
            //     $.ajax({
            //         type: "POST",
            //         url: "mail.php",
            //         data: $(form).serialize(),
            //         success: function () {
            //             $( "#loader").hide();
            //             $( "#success").slideDown( "slow" );
            //             setTimeout(function() {
            //             $( "#success").slideUp( "slow" );
            //             }, 3000);
            //             form.reset();
            //         },
            //         error: function() {
            //             $( "#loader").hide();
            //             $( "#error").slideDown( "slow" );
            //             setTimeout(function() {
            //             $( "#error").slideUp( "slow" );
            //             }, 3000);
            //         }
            //     });
            //     return false; // required to block normal submit since you used ajax
            // }

        });


        wpcf7Elm.addEventListener( 'wpcf7submit', function( event ) {
            console.log( "wpcf7submit!" );
            $('.submit-btn button').prop('disabled', false);
            $( "#loader").hide();

        }, false );


        $('.submit-btn button').on('click', function () {
            setTimeout(() => {
                $(this).prop('disabled', true);
                $( "#loader").show();
            }, 100);
        });

    }






    /*==========================================================================
        WHEN DOCUMENT LOADING
    ==========================================================================*/


        $(window).on('load', function() {

            preloader();

            sliderBgSetting();

            toggleMobileNavigation();

            smallNavFunctionality();

            //service grid bg image setting
            makeBg($(".mk-bg-img"));

            if($("#map").length) {
                map();
            }

        });





    /*==========================================================================
        WHEN WINDOW SCROLL
    ==========================================================================*/
    $(window).on("scroll", function() {

		if ($(".site-header").length) {
            stickIt($(".sticky-header"), "sticky-on");
        }

        bgParallax();

        toggleBackToTopBtn();

    });



	/*==========================================================================
        clone social-list
    ==========================================================================*/


	function hoverElemSize() {
		var h = 0;
		var items = $('.test-hover');

		items.css('height', 'auto');

		//setTimeout(function () {
			if ($('html').hasClass('touchevents')) {

				items.each(function () {
					var i = $(this).height();
					if (h < i) {
						h = i;
					}
				}).promise().done(function () {
					items.css('height', h);
				});
			}

		//}, 100);

	}


$(document).ready(function () {

    // if ( ! Modernizr.objectfit ) {
    //     $('.post__image-container').each(function () {
    //         var $container = $(this),
    //             imgUrl = $container.find('img').prop('src');
    //         if (imgUrl) {
    //             $container
    //                 .css('backgroundImage', 'url(' + imgUrl + ')')
    //                 .addClass('compat-object-fit');
    //         }
    //     });
    // }



	$('.social-list_header').clone().appendTo('.navbar-collapse');

	hoverElemSize();
    fixWpImgAdaptive();
    fixParsedNewsImage();

	$('.square-hover-effect-parent').click(function () {
		$('.square-hover-effect-parent').removeClass('square-hover-effect-active');
		$(this).addClass('square-hover-effect-active');
	});

	$(document).click(function (e) {

		var div = $('.square-hover-effect-parent');

		if (!div.is(e.target) && div.has(e.target).length === 0) {
		    $('.square-hover-effect-parent').removeClass('square-hover-effect-active');
		}

	});


	if ($(".resume-form").length) {

		var dropArea = document.getElementById('drop-area');

        var defaultTextButton = $('.upload-btn').first().text();

		['dragenter', 'dragover', 'dragleave', 'drop'].forEach(function (eventName) {
			dropArea.addEventListener(eventName, preventDefaults, false);
		});

		function preventDefaults (e) {
			e.preventDefault();
			e.stopPropagation();
		}


		['dragenter', 'dragover'].forEach(function (eventName) {
			dropArea.addEventListener(eventName, highlight, false)
		});

		['dragleave', 'drop'].forEach(function (eventName) {
			dropArea.addEventListener(eventName, unhighlight, false)
		});

		function highlight(e) {
			dropArea.classList.add('highlight')
		};

		function unhighlight(e) {
			dropArea.classList.remove('highlight')
		};


		dropArea.addEventListener('drop', handleDrop, false);

		function handleDrop(e) {
			let dt = e.dataTransfer;
			let files = dt.files;

			document.getElementById('resume').files = files;

			// console.log(files);
		};

		function showFileName() {
			setTimeout(function () {
                var formData = new FormData(document.getElementById('contact-form-s2'));
                var filesName = formData.get('file').name;


                if(filesName.length) {
                    $('.upload-btn').text(filesName);
                } else {
                    $('.upload-btn').text(defaultTextButton);
                }


            }, 300);
		}

		document.getElementById('resume').onchange = showFileName;

        dropArea.addEventListener('drop', function (e) {
			showFileName();
		});
	}


    // Contact page form
    if ($("#contact-form-s2").length) {



        $("#contact-form-s2").validate({
            rules: {
                f_name: {
                    minlength: 2
                },

                l_name: {
                    minlength: 2
                },

            },

        });

        setTimeout(function () {
            let wpcf7Elm2 = document.querySelector( '.wpcf7' );


            wpcf7Elm2.addEventListener( 'wpcf7submit', function( event ) {
                console.log( "wpcf7submit!" );
                $('.submit-btn button').prop('disabled', false);
                $( "#loader").hide();

            }, false );


            $('.submit-btn button').on('click', function () {
                setTimeout(() => {
                    $(this).prop('disabled', true);
                    $( "#loader").show();
                }, 100);
            });
        }, 500);


    }


    function fixWpImgAdaptive() {
        $('.post-body img').each(function (i) {
            let width = $(this).attr('width');

            $(this).css('maxWidth', width);
            $(this).css('width', '100%');
        });
    }
    
    
    function fixParsedNewsImage() {
        let _this = $('.b-blog .post-body img');

        if (_this.length) {
            _this.parent().parent().css({'paddingRight':'20px', 'textAlign':'center'});
            _this.parent().siblings().css({'display':'inline', 'lineHeight':'27px'});
        }

    }


    if ($('#get-quote-modal').length) {

        $('.popup-modal').magnificPopup({
            type: 'inline',
            preloader: false,
            focus: '#username',
            modal: true
        });
        $(document).on('click', '.popup-modal-dismiss', function (e) {
            e.preventDefault();
            $.magnificPopup.close();
        });

    }
});


	/*==========================================================================
        Fix blog page for IE11
    ==========================================================================*/


	function fixIEHeadingSize() {
		var elem = $('.page-title');
		var elem_h = $('.page-title .row');



		if (elem_h.height() < 260) {
			elem.css('height', '260px');
		} else {
			elem.css('height', 'auto');
		}

	}

	$(document).ready(function () {
		fixIEHeadingSize();
	});



    /*==========================================================================
        WHEN WINDOW RESIZE
    ==========================================================================*/
    $(window).on("resize", function() {

        toggleClassForSmallNav();

		fixIEHeadingSize();


		if($(".recent-projects-grids").length) {
            setMcustomScrollBar($(".recent-projects-grids"));
        }


        clearTimeout($.data(this, 'resizeTimer'));

        $.data(this, 'resizeTimer', setTimeout(function() {
            smallNavFunctionality();
			hoverElemSize();
		}, 200));

    });



})(window.jQuery);




