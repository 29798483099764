function SimpleTab(datasetId) {
	this.datasetId   = typeof datasetId === "string" ? datasetId : null;
	this.datasetMark = "classMark";
	this.showClass   = "show";
}

SimpleTab.prototype.init = function() {
	var datasetId   = this.datasetId;
	var datasetMark = this.datasetMark;
	var showClass   = this.showClass;

	if(datasetId !== null) {
		document.addEventListener("click", function(event) {
			event = event || window.event;
			var target = event.target;

			if(target instanceof HTMLElement && target.dataset[datasetId] !== undefined && target.dataset[datasetMark]) {
				let sectionId = target.dataset[datasetId];
				let markClass = target.dataset[datasetMark];
				let sections  = document.getElementsByClassName(markClass);

				for (let i = 0; i < sections.length; i++) {
					const element = sections[i];
					
					if(element.id !== sectionId) {
						// hide
						element.style.opacity = "0";
						setTimeout(() => {
							element.classList.remove(showClass);
						}, 500);
					} else {
						// show
						setTimeout(() => {
							element.classList.add(showClass);

							setTimeout(() => {
								element.style.opacity = "1";
							}, 100);
						}, 500);
						
					}
				}
			}
		});
	}
}