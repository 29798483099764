var VideoPlayer = (function() {
	var __private = {
		blockClass: "__js-video",
		modal: new ModalWindow(),
		dataset: {
			type: "videoType"
		},
		type: {
			yt: "youtube",
			vd: "video"
		},

		set() {
			var links = document.getElementsByClassName(this.blockClass);

			for (let i = 0; i < links.length; i++) {
				const link = links[i];
				
				if(link instanceof HTMLAnchorElement) this.listener(link);
			}

			if(links.length > 0) this.modal.create();
		},

		listener(link) {
			if(link instanceof HTMLAnchorElement) {
				link.addEventListener("click", function(event) {
					event = event || window.event;
					event.preventDefault();

					var type = typeof this.dataset[__private.dataset.type] === "string" ? this.dataset[__private.dataset.type].toLowerCase() : __private.type.yt;
					var href = this.href;

					if(type && href) __private.box(type, href);
				});
			}
		},

		box(type, href) {
			var frame;

			switch (type) {
				case this.type.yt:
					frame = `
						<iframe src="${href}?autoplay=1" frameborder="0" allowfullscreen></iframe>
					`;
					break;
			
				case this.type.vd:
					frame = `
						<video controls="controls" autoplay="autoplay">
							<source src="${href}" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'>
							The video element is not supported by your browser.
						</video>
					`;
					break;
			};

			if(frame) this.modal.open(frame);
		}
	};

	return {
		init() {
			__private.set();
		}
	};
})();