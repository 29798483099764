var ImageMitiature = (function() {
	var __private = {
		blockClass: "__js-img-miniature",
		modal: new ModalWindow(),
		closeId: "__js-modal-close",

		set() {
			var links = document.getElementsByClassName(this.blockClass);

			for (let i = 0; i < links.length; i++) {
				const link = links[i];
				
				if(link instanceof HTMLAnchorElement) this.listener(link);
			}

			if(links.length > 0) this.modal.create();
		},

		listener(link) {
			if(link instanceof HTMLAnchorElement) {
				link.addEventListener("click", function(event) {
					event = event || window.event;
					event.preventDefault();

					var href = this.href;

					if(href) {
						let frame = `<img src="${href}" alt="">`;

						__private.modal.open(frame);
					}
				});
			}
		}
	};

	return {
		init() {
			__private.set();
		}
	};
})();