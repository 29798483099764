/**
 * VANILLA JS
 */

(function() {
	"use strict";

	document.addEventListener("DOMContentLoaded", function() {

		// Video modal window
		VideoPlayer.init();

		// Image modal window
		ImageMitiature.init();

		// Factory sections
		let factory = new SimpleTab("factoryId");
		factory.init();

	});
})();